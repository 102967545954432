import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Kennzahlen (KPI) und Auswertungen",
  "description": "Welche Auswertungsmöglichkeiten (Generierung von Kennzahlen, Statistikwerten etc.) bietet das inriva Karrierportal?",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Online & live können Sie folgende eingebauten Funktionen nutzen:`}</p>
    <img src="/images/gettingstarted/inriva-analytics.png" alt="KPI" style={{
      "width": "100%"
    }} />
    <ul>
      <li parentName="ul">{`alle vom Bewerber erfassten Daten (Bewerber und Bewerbungen) (auch per Excel-Export oder Datenfeed) während der Aufbewahrungszeit für Statistiken heranziehen`}</li>
      <li parentName="ul">{`Bewerberqualität messen`}</li>
      <li parentName="ul">{`Ausschreibungsqualität messen`}</li>
      <li parentName="ul">{`Prozesskennzahlen und Durchlaufzeiten wie z.B. die Time to Hire, Time to Interview, Time to Feedback messen (hierzu müssen die von ihnen frei konfigurierbaren Pools entsprechendeingerichtet werden)`}</li>
      <li parentName="ul">{`Rate der abgesprungenen Bewerber auswerten (Application completion rate)`}</li>
      <li parentName="ul">{`Reaktionszeit und Nutzungshäufigkeit im Team messen`}</li>
      <li parentName="ul">{`Bewerberquellenanalyse (Anzahl der Bewerbungen pro Quelle, Kosten/Nutzen pro Quelle, Top-Bewerber-Quelle, etc.)`}</li>
      <li parentName="ul">{`Empfehlungs-Bewerber-Rate messen `}</li>
    </ul>
    <p>{`Diese werden laufend auf Basis des Feedbacks unserer Kunden erweitert.`}</p>
    <p>{`Darüber hinaus können wir Ihnen mittels "PowerBI" ein individuelles „HR-Cockpit“ zusammenstellen dass Kennzahlen auch über die Live-Auswertung hinaus bietet und auch die Daten aus Ihrem Abrechnungssystem verwenden kann.`}</p>
    <p>{`Beispiel:`}</p>
    <ul>
      <li parentName="ul">{`Kosten pro Besetzung`}</li>
      <li parentName="ul">{`wie viele der eingestellten Kandidaten bleiben nach 1 Jahr im Unternehmen`}</li>
      <li parentName="ul">{`Mitarbeiterentwicklung`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <img src="/images/gettingstarted/hr-analytics.jpg" alt="KPI" style={{
      "width": "100%"
    }} />
    <p>{`siehe auch `}<a parentName="p" {...{
        "href": "/articles/was-sind-powerapps"
      }}>{`PowerApps`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      